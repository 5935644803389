import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {message, Button} from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import usePosErrorHandler from '../../Hooks/usePosErrorHandler';
import useProductGroups, {CatType} from '../../Hooks/useProductGroups';
import {
  filterAllowedProductsBySelectedStore,
  filterExternalProducts,
} from '../../Components/ProductList/helper';
import usePosEdit from '../../Hooks/usePosEdit';

function getAllowVariantNames(products, variantGroups) {
  let variantNames = [];

  if (products.length > 0 && variantGroups.length > 0) {
    let allowGroupNames = [...new Set(products.map((p) => p.group))];
    let allowGroups = variantGroups.filter(
      (vg) => allowGroupNames.indexOf(vg.name) > -1,
    );

    for (let g of allowGroups) {
      for (let v of g.variants) {
        if (v.type === 'single') {
          variantNames = variantNames.concat(v.choices.map((c) => c.name));
        } else if (v.type === 'bool') {
          variantNames.push(v.name);
        }
      }
    }

    variantNames = [...new Set(variantNames)];
  }

  return variantNames;
}

const collection = 'product_stock';
export default function PosEditProductStock(props) {
  const {name, token} = qs.parse(props.location.search);
  const [store, setStore] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [stocks, setStocks] = React.useState([]);
  const [values, setValues] = React.useState({}); // product name & in_stock pair
  const {errorHandler, renderError} = usePosErrorHandler();
  const [meals] = useOutlet('mealCategories');
  const [variantGroups] = useOutlet('variantGroups');
  const [variantNames, setVariantNames] = React.useState([]);

  const {setDone} = usePosEdit({values});

  // force re-render while categories is fetched
  useOutlet('categories');

  React.useEffect(() => {
    (async () => {
      AppActions.setLoading(true);
      try {
        const store = await JStorage.fetchOneDocument('store', {name}, null, {
          token,
        });
        setStore(store);
      } catch (ex) {
        console.warn('PosEditProductStock ex', ex);
        errorHandler(ex);
      }
      AppActions.setLoading(false);
    })();
  }, [name, token]);

  const refresh = React.useCallback(async () => {
    if (store && meals?.length > 1 && variantGroups.length > 0) {
      AppActions.setLoading(true);
      try {
        const [products, stocks] = await Promise.all([
          AppActions.fetchProducts({onlyPublic: false}),
          JStorage.fetchAllDocuments(
            collection,
            {
              store_id: store.id,
            },
            null,
            null,
            {token},
          ),
        ]);

        let _products = filterAllowedProductsBySelectedStore({
          products,
          selectedStore: store,
          meals,
        });

        _products = _products.concat(filterExternalProducts({products, meals}));

        let _map = _products.reduce((sum, x) => {
          let stock = stocks.find((s) => s.name === x.name);
          sum[x.name] = stock ? stock.in_stock : true; // default is TRUE! if stock is not exists
          return sum;
        }, {});

        setProducts(_products);
        setStocks(stocks);

        let variantNames = getAllowVariantNames(products, variantGroups);
        variantNames = variantNames.filter(
          (v) => !['無', 'none', '中', '大', '冰', '熱'].includes(v),
        );
        setVariantNames(variantNames);

        for (let v of variantNames) {
          let stock = stocks.find((s) => s.name === v);
          _map[v] = stock ? stock.in_stock : true;
        }

        setValues(_map);
      } catch (ex) {
        console.warn('PosEditProductStock ex', ex);
        errorHandler(ex);
      }
      AppActions.setLoading(false);
    }
  }, [store, meals, variantGroups]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const groups = useProductGroups({
    products,
    catType: CatType.LABEL,
  });

  const submit = async () => {
    AppActions.setLoading(true);
    try {
      let stocksUpdateTrusy = products
        .filter(
          (p) =>
            values[p.name] && stocks.findIndex((s) => s.name === p.name) > -1,
        )
        .map((p) => p.name);
      let stocksUpdateFalsy = products
        .filter(
          (p) =>
            !values[p.name] && stocks.findIndex((s) => s.name === p.name) > -1,
        )
        .map((p) => p.name);
      let stocksInsert = products
        .filter(
          (p) =>
            !values[p.name] &&
            stocks.findIndex((s) => s.name === p.name) === -1,
        )
        .map((p) => p.name);

      stocksUpdateTrusy = stocksUpdateTrusy.concat(
        variantNames.filter(
          (v) => values[v] && stocks.findIndex((s) => s.name === v) > -1,
        ),
      );

      stocksUpdateFalsy = stocksUpdateFalsy.concat(
        variantNames.filter(
          (v) => !values[v] && stocks.findIndex((s) => s.name === v) > -1,
        ),
      );

      stocksInsert = stocksInsert.concat(
        variantNames.filter(
          (v) => !values[v] && stocks.findIndex((s) => s.name === v) === -1,
        ),
      );

      await JStorage.bulkWriteDocuments(
        collection,
        [
          ...stocksUpdateTrusy.map((name) => ({
            method: 'update_one',
            payload: {
              query: {
                store_id: store.id,
                name,
              },
              data: {
                store_id: store.id,
                name,
                in_stock: true,
              },
            },
          })),
          ...stocksUpdateFalsy.map((name) => ({
            method: 'update_one',
            payload: {
              query: {
                store_id: store.id,
                name,
              },
              data: {
                store_id: store.id,
                name,
                in_stock: false,
              },
            },
          })),
          ...stocksInsert.map((name) => ({
            method: 'insert_one',
            payload: {
              query: {
                store_id: store.id,
                name,
              },
              data: {
                store_id: store.id,
                name,
                in_stock: false, // default is TRUE! if stock is not exists
              },
            },
          })),
        ],

        {token},
      );

      refresh();

      setDone(true);
    } catch (ex) {
      console.warn(ex);
      errorHandler(ex);
      message.error('發生錯誤, 無法進行編輯！');
    }
    AppActions.setLoading(false);
  };

  return (
    <Wrapper>
      <h1>{store?.name} 編輯庫存</h1>
      {renderError()}
      {!store ? null : (
        <div className="content">
          <div className="group">
            <div className="header">
              <h3>全部商品</h3>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setValues({
                    ...products.reduce((sum, p) => {
                      sum[p.name] = true;
                      return sum;
                    }, {}),
                    ...variantNames.reduce((sum, v) => {
                      sum[v] = true;
                      return sum;
                    }, {}),
                  });
                }}>
                全選
              </Button>

              <Button
                size="small"
                style={{marginLeft: 5}}
                onClick={() => {
                  setValues({});
                }}>
                全不選
              </Button>
            </div>
          </div>

          {groups
            .filter((group) => group.items?.length > 0)
            .map((group) => {
              const {cat, items} = group;
              return (
                <div key={cat.name} className="group">
                  <div className="header">
                    <h3>{cat.name}</h3>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        const nextValues = {...values};
                        for (const p of items) {
                          nextValues[p.name] = true;
                        }
                        setValues(nextValues);
                      }}>
                      全選
                    </Button>
                    <Button
                      size="small"
                      style={{marginLeft: 5}}
                      onClick={() => {
                        const nextValues = {...values};
                        for (const p of items) {
                          nextValues[p.name] = false;
                        }
                        setValues(nextValues);
                      }}>
                      全不選
                    </Button>
                  </div>

                  {items.map((product) => {
                    const id = `${cat.name}-${product.id}`;
                    return (
                      <div key={product.id}>
                        <input
                          type="checkbox"
                          id={id}
                          checked={!!values[product.name]}
                          onChange={(e) => {
                            setValues((prev) => ({
                              ...prev,
                              [product.name]: !!e.target.checked,
                            }));
                          }}
                        />
                        <label htmlFor={id} style={{marginLeft: 8}}>
                          {product.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              );
            })}

          <div className="group">
            <div className="header">
              <h3>商品選項</h3>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  const nextValues = {...values};
                  for (const v of variantNames) {
                    nextValues[v] = true;
                  }
                  setValues(nextValues);
                }}>
                全選
              </Button>
              <Button
                size="small"
                style={{marginLeft: 5}}
                onClick={() => {
                  const nextValues = {...values};
                  for (const v of variantNames) {
                    nextValues[v] = false;
                  }
                  setValues(nextValues);
                }}>
                全不選
              </Button>
            </div>
            {variantNames.map((v) => (
              <div key={v}>
                <label>
                  <input
                    type="checkbox"
                    checked={!!values[v]}
                    onChange={(e) => {
                      setValues((prev) => ({
                        ...prev,
                        [v]: !!e.target.checked,
                      }));
                    }}
                  />
                  <span style={{marginLeft: 8}}>{v}</span>
                </label>
              </div>
            ))}
          </div>

          <div className="actions">
            <Button type="primary" onClick={submit}>
              確認修改
            </Button>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #eee;
  padding: 12px;
  min-height: 100vh;

  & > h1 {
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: 400;
  }

  & > .content {
    margin: 0 auto;
    border-radius: 6px;
    padding: 12px;
    background-color: white;

    & > .group {
      padding: 10px 0;
      & > .header {
        & > h3 {
          flex: 1;
          font-weight: 400;
        }

        display: flex;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
      }

      & > * {
        margin: 10px 0;
        display: flex;
        align-items: center;
      }
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
