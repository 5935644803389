import {useOutlet} from 'reconnect.js';
import {getFlattenCategories} from '../Utils/CatUtil';

export const CatType = {
  LABEL: {
    outlet: 'categories',
    field: 'labels',
  },
  PROMOTION: {
    outlet: 'promoCategories',
    field: 'promotions',
  },
};

export default function useProductGroups({products, catType}) {
  // force the React hook to execute while categories is fetched
  useOutlet(catType.outlet);

  const categories = getFlattenCategories(catType.outlet);
  const groups = [];

  if (Array.isArray(categories)) {
    const secLevelCats = categories.filter(
      (cat) => cat.name.split('-').length === 2,
    );
    for (const cat of secLevelCats) {
      const [cat1, cat2] = cat.name.split('-');
      const catL1 = categories.find((c) => c.name === cat1) || {};
      const group = {
        cat,
        catL1,
        items: [],
      };
      for (const product of products) {
        try {
          if ((product[catType.field] || []).find((p) => p === cat.name)) {
            group.items.push(product);
          }
        } catch (ex) {}
      }
      groups.push(group);
    }
  }

  return groups;
}
